import axios from 'axios';

let prefix = "/idle"

const IdleApi = {
	
	getIdleRateList(payload){
		return axios.get( prefix + "/list", {params: payload})
	},
	createIdleRate(payload){
		return axios.post( prefix + "/create", payload)
	},
	updateIdleRate(payload){
		return axios.post( prefix + "/update", payload)
	},
}

export default IdleApi;