import axios from 'axios';

let prefix = "/ocpi/2.2/tarrif"

const TarrifApi = {
	
	getTarrifList(payload){
		return axios.get( prefix + "/list", {params: payload})
	},
	getTarrifTypeList(payload){
		return axios.get( prefix + "/list-type", {params: payload})
	},
	createTarrif(payload){
		return axios.post( prefix + "/create", payload)
	},
	updateTarrif(payload){
		return axios.post( prefix + "/update", payload)
	},
}

export default TarrifApi;