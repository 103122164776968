import axios from 'axios';

let prefix = "/vehicle"

const VehicleApi = {
	
	getVehicleList(payload){
		return axios.get( prefix + "/list", {params: payload})
	},
	createVehicle(payload){
		return axios.post( prefix + "/create", payload)
	},
	deleteVehicle(payload){
		return axios.delete( prefix + "/delete", {params: payload})
	},
	updateVehicle(payload){
		return axios.post( prefix + "/update", payload)
	},
}

export default VehicleApi;