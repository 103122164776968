import axios from 'axios';

let prefix = "/ocpp"

const OcppApi = {
	resetEvse(payload){
		return axios.post( prefix + "/reset", payload);
	},
	updateFirmware(payload){
		return axios.post( prefix + "/update-firmware", payload);
	},	
}

export default OcppApi;