import axios from 'axios';

let prefix = "/evse-rfid"

const EvseRfidApi = {
	
	getEvseRfidList(payload){
		return axios.get( prefix + "/list", {params: payload})
	},
	archiveEvseRfid(payload){
		return axios.delete( prefix + "/archive", {params: payload})
	},
	createEvseRfid(payload){
		return axios.post( prefix + "/create", payload)
	},
	updateEvseRfid(payload){
		return axios.post( prefix + "/update", payload)
	},
}

export default EvseRfidApi;