import axios from 'axios';

let prefix = "/ocpi/2.2/locations"

const LocationApi = {
	
	getLocationList(payload){
		return axios.get( prefix + "/", {params: payload})
	},
	archiveLocation(payload){
		return axios.delete( prefix + "/delete", {params: payload})
	},
	createLocation(payload){
		return axios.post( prefix + "/create", payload)
	},
	updateLocation(payload){
		return axios.post( prefix + "/update", payload)
	},
	updatePublishStatus(payload){
		return axios.post( prefix + "/update-publish-status", payload)
	},
	setLocationImage(payload, onUploadProgress = {} ){
		return axios.post(prefix + "/upload", payload,{
			headers: { 'Content-Type': 'multipart/form-data'},
			onUploadProgress
		})
	},
	clearLocationImage(payload){
		return axios.delete( prefix + "/delete-image", {params: payload})
	},
	getLocationTypeList(payload){
		return axios.get( prefix + "/getLocationType", {params: payload})
	},
	updateLocationType(payload){
		return axios.post( prefix + "/updateLocationType", payload)
	},
	createLocationType(payload){
		return axios.post( prefix + "/createLocationType", payload)
	},
	getParkingTypeList(payload){
		return axios.get( prefix + "/getParkingType", {params: payload})
	},
	updateParkingType(payload){
		return axios.post( prefix + "/updateParkingType", payload)
	},
	createParkingType(payload){
		return axios.post( prefix + "/createParkingType", payload)
	},
	checkLocationEvsePercentageAllocation(payload){
		return axios.get( prefix + "/checkLocationEvsePercentageAllocation", {params: payload})
	}
}

export default LocationApi;