import axios from 'axios';

let prefix = "/user-booking"

const UserBookingApi = {
	
	getUserBookingList(payload){
		return axios.get( prefix + "/list", {params: payload})
	},
	getUserBookingStatusRefs(payload){
		return axios.get( prefix + "/status-refs", {params: payload})
	},
}

export default UserBookingApi;