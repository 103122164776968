import axios from 'axios';

let prefix = "/to-do"

const ToDoApi = {
	
	getToDoList(payload){
		return axios.get( prefix + "/list", {params: payload})
	},
	markAsComplete(payload){
		return axios.post( prefix + "/update-status", payload)
	},
}

export default ToDoApi;