<template>
	<div id="app">
		<v-app>
			<v-main v-if="auth_ready" class="background">
				<transition name="fade">
					<router-view></router-view>
				</transition>
			</v-main>
			<v-main v-if="!auth_ready">
				<v-row justify="center" class="text-center fill-height">
					<v-col cols="12" align-self="end">
						<v-progress-circular
							:size="70"
							:width="7"
							color="primary"
							indeterminate
						></v-progress-circular>
					</v-col>
					<v-col class="font-weight-bold">Loading ...</v-col>
				</v-row>
			</v-main>
		</v-app>
	</div>
</template>

<script>
export default {
	data(){
		return {
			auth_ready : true,
		}
	},
	mounted() {
		this.setTheme();
	},
	methods:{
		setTheme(){
			let isDarkTheme = JSON.parse(localStorage.getItem("dark-theme"))
			this.$vuetify.theme.dark = isDarkTheme || false;
		}
	}
}
</script>

<style scoped>
.theme--dark.v-application {
	background: #303030;
}
.theme--light.v-application {
	background: #F5F5F5;
}
</style>