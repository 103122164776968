import axios from 'axios';

let prefix = "/booking-price"

const BookingPriceApi = {
	
	getBookingPriceList(payload){
		return axios.get( prefix + "/list", {params: payload})
	},
	createBookingPrice(payload){
		return axios.post( prefix + "/create", payload)
	},
	updateBookingPrice(payload){
		return axios.post( prefix + "/update", payload)
	},
}

export default BookingPriceApi;