import axios from 'axios';

let prefix = "/user-socialize"

const UserSocializeApi = {
	
	getUserSocializeList(payload){
		return axios.get( prefix + "/list", {params: payload})
	},

}

export default UserSocializeApi