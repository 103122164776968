import axios from 'axios';

let prefix = "/countries"

const CountryApi = {
	
	getCountryList(payload){
		return axios.get( prefix + "/list", {params: payload})
	},

}

export default CountryApi;