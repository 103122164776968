import Vue from 'vue'

import 'vuetify/dist/vuetify.min.css';
import Vuetify from 'vuetify';

Vue.use(Vuetify);

export default new Vuetify({
	theme: {
		dark : false,
		themes: {
			light: {
				primary: '#40C1AC',
				secondary: '#FFFFFF',
				'tertiary' : '#3EA6FF',
				'background': '#f0f2f5',
				'opposite': '#202020',
			},
			dark: {
				primary: '#F28C19',
				secondary: '#202020',
				'tertiary' : '#3EA6FF',
				'background': '#181818',
				'opposite': '#FFFFFF',
			},
		},
	}
})