import axios from 'axios';

let prefix = "/user-location"

const UserLocationApi = {
	
	getUserHomeList(payload){
		return axios.get( prefix + "/list-user", {params: payload})
	},
	getHouseList(payload){
		return axios.get( prefix + "/list-house", {params: payload})
	},
	archiveUserLocation(payload){
		return axios.delete( prefix + "/delete-user", {params: payload})
	},
	addUser(payload){
		return axios.post( prefix + "/add-user", payload)
	},
}

export default UserLocationApi;