import VueRouter from 'vue-router'
import { setPageTitle } from '@/helpers/pageTitle'

// Layout
const BaseLayout = () => import('@/views/_layouts/BaseLayout')

// Common Pages
const Comingsoon = () => import('@/views/_general/Comingsoon')
const Error404 = () => import('@/views/_general/404')
const ForgotPassword = () => import('@/views/_general/ForgotPassword')
const VerifyEmail = () => import('@/views/_general/VerifyEmail')
const Login = () => import('@/views/_general/Login')
const Register = () => import('@/views/_general/Register')
const ResetPassword = () => import('@/views/_general/ResetPassword')

// Pages
const AuthProfile = () => import('@/views/_auth/AuthProfile')
const UpdatePasswordPage = () => import('@/views/_auth/UpdatePasswordPage')

const Home = () => import('@/views/_general/Home')

const UserList = () => import('@/views/Users/UserList')
const LockUserList = () => import('@/views/Users/LockUserList')
const UserProfile = () => import('@/views/Users/UserProfile')

const UserTokenList = () => import('@/views/UserTokens/UserTokenList')

// token type
const TokenTypeList = () => import('@/views/TokenTypes/TokenTypeList')

const SupportiveSingle = () => import('@/views/Supports/SupportiveSingle')

const FAQList = () => import('@/views/Supports/FAQ')

const AuditTrailList = () => import('@/views/AuditTrails/AuditTrailList')
const AuditTrailProfile = () => import('@/views/AuditTrails/AuditTrailProfile')

const AnnouncementList = () => import('@/views/Announcements/AnnouncementList')

const PosterList = () => import('@/views/Posters/PosterList')

const SystemVariableList = () => import('@/views/SystemVariables/SystemVariableList')

const BroadcastList = () => import('@/views/Broadcasts/BroadcastList')

const IdVerificationList = () => import('@/views/IdVerifications/IdVerificationList')

const VideoTestimonyList = () => import('@/views/VideoTestimonies/VideoTestimonyList')

const VehicleList = () => import('@/views/Vehicles/VehicleList')

const LocationList = () => import('@/views/Locations/LocationList')
const LocationProfile = () => import('@/views/Locations/LocationProfile')

const HouseLocationList = () => import('@/views/HouseLocations/HouseLocationList')
const HouseLocationProfile = () => import('@/views/HouseLocations/HouseLocationProfile')

const SessionList = () => import('@/views/Sessions/SessionList')
const SessionProfile = () => import('@/views/Sessions/SessionProfile')

const PartnerList = () => import('@/views/Partners/PartnerList')

const CommissionRecordList = () => import('@/views/CommissionRecords/CommissionRecordList')

const PaymentRedirection = () => import('@/views/_general/PaymentRedirection')

const PaymentRecordList = () => import('@/views/PaymentRecords/PaymentRecordList')

const BusinessDetailList = () => import('@/views/BusinessDetails/BusinessDetailList')
const BusinessDetailProfile = () => import('@/views/BusinessDetails/BusinessDetailProfile')

const ConnectorProfile = () => import('@/views/Connectors/ConnectorProfile')

const ConnectorCommissionRecordList = () => import('@/views/Connectors/ConnectorCommissionRecordList')

const TarrifList = () => import('@/views/Tarrifs/TarrifList')

const IdleList = () => import('@/views/Idles/IdleList')

const IssueReportList = () => import('@/views/IssueReports/IssueReportList')

const LocationTypeList = () => import('@/views/LocationType/LocationTypeList')

const DownloadPage = () => import('@/views/_general/DownloadPage')

const IdleConnectorList = () => import('@/views/Connectors/IdleConnectorList')

const SettingPage = () => import('@/views/SystemVariables/SettingPage')

const UpdateFirmwarePage = () => import('@/views/UpdateFirmware/UpdateFirmwarePage')

const BookingPriceList = () => import('@/views/BookingPrices/BookingPriceList')

const UserBookingList = () => import('@/views/UserBookings/UserBookingList')

const ToDoList = () => import('@/views/ToDos/ToDoList')

const InstallerList = () => import('@/views/Installers/InstallerList')

// Routes
// ** for more information, kindy refer to https://github.com/websanova/vue-auth/blob/master/docs/Privileges.md
// ** auth: true <- can be access only if auth.check = true
// ** auth: false <- cannot be access is auth.check = true
// ** auth: null, title: '' <- no auth rule
// ** auth: [1,2] <- can be access is auth.user.role matched

const baseLayoutRoutes = [
	{ path: '/comingsoon', name: 'admin.home2', component: Comingsoon, meta: { auth: [1, 4], title: 'Coming Soon' } },
	{ path: '/home', name: 'home', component: Home, meta: { auth: [1, 4], title: 'Home' } },

	// user
	{ path: '/user/list', name: 'user.list', component: UserList, meta: { auth: [1], title: 'User List' } },
	{ path: '/my/profile', name: 'my.profile', component: AuthProfile, meta: { auth: [1], title: 'Profile' } },
	{ path: '/user/profile/:id', name: 'user.profile', component: UserProfile, meta: { auth: [1], title: 'User Profile' } },
	{ path: '/user/lock-list', name: 'user.lock-list', component: LockUserList, meta: { auth: [1], title: 'Lock User List' } },

	// token type
	{ path: '/token-type/list', name: 'token_type.list', component: TokenTypeList, meta: { auth: [1], title: 'Token Type List' } },

	// support
	{ path: '/support/:type', name: 'support.supportive_list', component: SupportiveSingle, meta: { auth: [1], title: 'Supportive Info' } },
	// { path: '/support/terms-and-conditions', name: 'support.terms-and-conditions', component: SupportiveSingle, meta: { auth: [1], title: 'T&C', params: { type: 'terms-and-conditions' } } },
	{ path: '/support/faq', name: 'support.faq', component: FAQList, meta: { auth: [1], title: 'FAQ', params: { type: 'faq' } } },


	//audit trails
	{ path: '/audit-trail/list', name: 'audit-trail.list', component: AuditTrailList, meta: { auth: [1], title: 'Audit Trail List' } },
	{ path: '/audit-trail/profile/:id', name: 'audit-trail.profile', component: AuditTrailProfile, meta: { auth: [1], title: 'Audit Trail Profile' } },

	{ path: '/user-token/list', name: 'user-token.list', component: UserTokenList, meta: { auth: [1], title: 'User Token List' } },

	{ path: '/announcement/list', name: 'announcement.list', component: AnnouncementList, meta: { auth: [1], title: 'Announcement List' } },

	{ path: '/poster/list', name: 'poster.list', component: PosterList, meta: { auth: [1], title: 'Poster List' } },

	{ path: '/settings/list', name: 'system-variable.list', component: SystemVariableList, meta: { auth: [1], title: 'System Variable List' } },

	// broadcast
	{ path: '/broadcast/list', name: 'broadcast.list', component: BroadcastList, meta: { auth: [1], title: 'Broadcast list' } },

	// id-verification
	{ path: '/id-verification/list', name: 'id-verification.list', component: IdVerificationList, meta: { auth: [1], title: 'ID Verification list' } },

	// video-testimony
	{ path: '/video-testimony/list', name: 'video-testimony.list', component: VideoTestimonyList, meta: { auth: [1], title: 'Video Testimony list' } },

	// vehicle
	{ path: '/vehicle/list', name: 'vehicle.list', component: VehicleList, meta: { auth: [1], title: 'Vehicle List' } },
	
	// location
	{ path: '/location/list', name: 'location.list', component: LocationList, meta: { auth: [1, 4], title: 'Location List' } },
	{ path: '/location/profile/:id', name: 'location.profile', component: LocationProfile, meta: { auth: [1, 4], title: 'Location Profile' } },

	// house location
	{ path: '/house-location/list', name: 'house-location.list', component: HouseLocationList, meta: { auth: [1], title: 'House Location List' } },
	{ path: '/house-location/profile/:id', name: 'house-location.profile', component: HouseLocationProfile, meta: { auth: [1], title: 'House Profile' } },
	
	{ path: '/session/list', name: 'session.list', component: SessionList, meta: { auth: [1], title: 'Session List' } },
	{ path: '/session/list/:id', name: 'session.list.id', component: SessionList, meta: { auth: [1], title: 'Session List' } },
	{ path: '/session/profile/:id', name: 'session.profile.id', component: SessionProfile, meta: { auth: [1], title: 'Session Profile' } },

	{ path: '/partner/list', name: 'partner.list', component: PartnerList, meta: { auth: [1], title: 'Partner List' } },

	{ path: '/commission-record/list', name: 'commission-record.list', component: CommissionRecordList, meta: { auth: [1], title: 'Commission Record List' } },
	{ path: '/payment-record/list', name: 'payment-record.list', component: PaymentRecordList, meta: { auth: [1], title: 'Payment Record List' } },
	{ path: '/payment-record/list/:id', name: 'payment-record.list.byID', component: PaymentRecordList, meta: { auth: [1], title: 'Payment Record List' } },
	
	{ path: '/business-detail/list', name: 'business-detail.list', component: BusinessDetailList, meta: { auth: [1], title: 'Business Detail List' } },
	{ path: '/business-detail/profile/:id', name: 'business-detail.profile', component: BusinessDetailProfile, meta: { auth: [1], title: 'Business Detail Profile' } },
	
	{ path: '/connector/profile/:id', name: 'connector.profile', component: ConnectorProfile, meta: { auth: [1], title: 'Connector profile' } },
	
	{ path: '/connector/commission-record/:id/:type', name: 'connector.commission-record', component: ConnectorCommissionRecordList, meta: { auth: [1], title: 'Connector Commission Record List' } },
	{ path: '/commission-record/all', name: 'connector.commission-record', component: ConnectorCommissionRecordList, meta: { auth: [1], title: 'Connector Commission Record List' } },
	
	{ path: '/tarrif/list', name: 'tarrif.list', component: TarrifList, meta: { auth: [1], title: 'Tarrif List' } },
	
	{ path: '/idle/list', name: 'idle.list', component: IdleList, meta: { auth: [1], title: 'Idle List' } },
	
	{ path: '/issue-report/list', name: 'issue-report.list', component: IssueReportList, meta: { auth: [1], title: 'Issue Report List' } },
	
	{ path: '/location-type/list', name: 'location-type.list', component: LocationTypeList, meta: { auth: [1], title: 'Location Type List' } },
	
	{ path: '/idle-connector/list', name: 'idle-connector.list', component: IdleConnectorList, meta: { auth: [1], title: 'Idle Connector List' } },
	
	{ path: '/update-firmware', name: 'update-firmware.page', component: UpdateFirmwarePage, meta: { auth: [1], title: 'Update Firmware' } },

	{ path: '/setting', name: 'setting.page', component: SettingPage, meta: { auth: [1], title: 'Settings' } },
	
	{ path: '/auth/update-password', name: 'update-password.page', component: UpdatePasswordPage, meta: { auth: [1], title: 'Update Password' } },

	{ path: '/booking-price/list', name: 'booking-price.list', component: BookingPriceList, meta: { auth: [1], title: 'Booking Price List' } },
	
	{ path: '/user-booking/list', name: 'user-booking.list', component: UserBookingList, meta: { auth: [1], title: 'User Booking List' } },
	{ path: '/to-do/list', name: 'to-do.list', component: ToDoList, meta: { auth: [1], title: 'To Do List' } },

	{ path: '/installer/list', name: 'installer.list', component: InstallerList, meta: { auth: [1], title: 'Installer List' } },
]

const routes = [

	{ path: '/', name: 'login', component: Login, meta: { auth: null, title: 'Login' } },
	{ path: '/login', name: 'login', component: Login, meta: { auth: null, title: 'Login' } },
	{ path: '/register/:code', name: 'register', component: Register, meta: { auth: null, title: 'Register' } },
	{ path: '/password/reset/:token', name: 'reset.password', component: ResetPassword, meta: { auth: null, title: 'Reset Password' } },
	{ path: '/forgot-password', name: 'forgot.password', component: ForgotPassword, meta: { auth: null, title: 'Forgot Password' } },
	{ path: '/email-verify/:token', name: 'verify.email', component: VerifyEmail, meta: { auth: null, title: 'Verify Email' } },
	{ path: '/payment-redirection', name: 'payment.redirection', component: PaymentRedirection, meta: { auth: null, title: 'Payment Redirection' } },
	{ path: '/share-qrcode/:qrcode', name: 'share.qrcode', component: DownloadPage, meta: { auth: null, title: 'Charger Info' } },

	// base_layout
	{ path: '/home', name: 'base_layout', component: BaseLayout, children: baseLayoutRoutes },

	{ path: '*', name: '404', component: Error404, meta: { auth: null, title: '404' } },
]

const router = new VueRouter({
	history: true,
	mode: 'history',
	routes,
})

router.beforeEach((to, from, next) => {
	setPageTitle(to.meta.title)
	return next()
});

export default router