import axios from 'axios';

let prefix = "/parking-types"

const ParkingTypeApi = {
	
	getParkingTypeList(payload){
		return axios.get( prefix + "/list", {params: payload})
	},

}

export default ParkingTypeApi