import axios from 'axios';

let prefix = "/user"

const InstallerApi = {
	
	getInstallerList(payload){
		return axios.get( prefix + "/list-installer", {params: payload})
	},
	getInstallerInfo(id, payload){
		return axios.get( prefix + "/get/"+id, {params: payload})
	},
	createInstaller(payload){
		return axios.post( prefix + "/create-installer", payload)
	},
	updateInstaller(payload){
		return axios.post( prefix + "/update-installer", payload)
	},
	archiveInstaller(payload){
		return axios.delete( prefix + "/delete-installer", {params: payload})
	},
	updateInstallerPassword(payload){
		return axios.post( prefix + "/password/update-installer", payload)
	},
}

export default InstallerApi;