import axios from 'axios';

let prefix = "/payment-record"

const PaymentRecordApi = {
	
	getPaymentRecordList(payload){
		return axios.get( prefix + "/list", {params: payload})
	},
	getSubsCard(payload){
		return axios.get( prefix + "/get-card-subscription", {params: payload})
	}
}

export default PaymentRecordApi;