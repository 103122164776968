import axios from 'axios';

let prefix = "/issue-report"

const IssueReportApi = {
	
	listIssueReport(payload){
		return axios.get( prefix + "/list", {params: payload})
	},
	markAsResolve(payload){
		return axios.post( prefix + "/mark-as-resolve", payload)
	},
}

export default IssueReportApi;