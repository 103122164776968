import axios from 'axios';

let prefix = "/partner"

const PartnerApi = {
	
	getPartnerList(payload){
		return axios.get( prefix + "/list", {params: payload})
	},
	getAvailablePercentage(payload){
		return axios.get( prefix + "/check-percentage-allocation", {params: payload})
	},
	archivePartner(payload){
		return axios.delete( prefix + "/archive", {params: payload})
	},
	createPartner(payload){
		return axios.post( prefix + "/create", payload)
	},
	updatePartner(payload){
		return axios.post( prefix + "/update", payload)
	},

}

export default PartnerApi;