import axios from 'axios';

let prefix = "/commission-record"

const CommissionRecordApi = {
	
	getCommissionRecordList(payload){
		return axios.get( prefix + "/list", {params: payload})
	},
	archiveCommissionRecord(payload){
		return axios.delete( prefix + "/archive", {params: payload})
	},
	createCommissionRecord(payload){
		return axios.post( prefix + "/create", payload)
	},
}

export default CommissionRecordApi;