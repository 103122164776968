import axios from 'axios';

let prefix = "/ocpi/2.2/session"

const SessionApi = {
	
	getSessionList(payload){
		return axios.get( prefix + "/get", {params: payload})
	},
	getSession(id, payload){
		return axios.get( prefix + "/get/" + id , {params: payload})
	},
	getSessionStatusRefs(payload){
		return axios.get( prefix + "/status-refs", {params: payload})
	},
	forceStopSession(payload){
		return axios.post( prefix + "/force-stop", payload)
	},
}

export default SessionApi;