import bearerDriver from '@websanova/vue-auth/drivers/auth/bearer'
import axios from '@websanova/vue-auth/drivers/http/axios.1.x'
import router from '@websanova/vue-auth/drivers/router/vue-router.2.x'

// Auth base configuration some of this options
// can be override in method calls

/*
override bearer Driver
- take token from resposne.data instead of response.header
*/
bearerDriver.response = function (res) {
	return res.data?.token
}

const config = {
	auth: bearerDriver,
	http: axios,
	router: router,
	tokenDefaultKey : 'config-X',
	stores: ['storage'],
	rolesVar: 'role_id',
	rolesKey: 'role_id',
	loginData: {url: 'auth/login', method: 'POST', redirect: '', fetchUser: true},
	refreshData: {url: 'auth/refresh', method: 'GET', enabled: false, interval: 30},
	logoutData: {url: 'auth/logout', method: 'POST', redirect: '/login', makeRequest: true},
	fetchData: {url: 'auth/get', method: 'GET', enabled: true},
	parseUserData (data) {
		return data.user || {}
	},
}

export default config